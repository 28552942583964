<template>
  <!-- root: zoom-wrap -->
  <div id="zoom-wrap" class="zoom-wrap d-flex">

    <!-- zoomer -->
    <v-zoomer
      ref="zoomComp"
      id="zoomComp"
      class="flex-grow-1"
      :max-scale="10"
      :zooming-elastic="false"
      :zoomed="model.zoomed"
      :pivot="'image-center'"
    >
      <img
        class="custom"
        :style="'max-width: ' + model.maxWidth"
        :src="model.imgSrc"
        :alt="`Generative AI: ${model.imgAlt} #${model.slctIndx + 1}/${model.imgList.length} | Generated`"
        :onload="onImgLoad"
      />
    </v-zoomer>
    <!-- zoomer -->

    <div v-if="model.btnShow === true" class="pos-abs-top-right mt-2 me-2 btn-func-area">
      <!-- 삭제 버튼 -->
      <a class="btn btn-sm btn-sqre btn-outline-white shadow-md"
        @click="emit('onRemvRsltImg', model.slctIndx)">
        <i class="fa-regular fa-trash-xmark"></i>
      </a>
      <!-- 삭제 버튼 -->
      <!-- 추가 버튼 -->
      <a class="btn btn-sm btn-sqre btn-outline-white shadow-md ms-2"
        @click="emit('onRsltImgAdd', model.slctIndx)">
        <i class="fa-solid fa-plus"></i>
      </a>
      <!-- 추가 버튼 -->
    </div>

    <div id="splide-wrap" class="splide-wrap">
      <!-- splidejs -->
      <splide
        ref="splideComp"
        v-if="model.imgList.length > 0"
        :options="model.options"
        @splide:resized="onSplideResize"
        @splide:move="onSplideMove"
      >
        <splide-slide v-for="(item, idx) in model.imgList" :key="idx">
          <img :src="item.pblcThumUrl" :alt="'Generative AI: ' + item.titl + ` #${idx + 1}/${model.imgList.length} | Generated`" role="button" />
        </splide-slide>
      </splide>
      <!-- splidejs -->
    </div>

    <!-- 모바일: 최소화 버튼 -->
    <a role="button" @click="emit('setFullScrn', false)"
      class="mobl-btn-max pos-abs-bottom-left btn btn-lg btn-flex btn-max btn-outline-white shadow-md">
      <i class="fa-solid fa-arrows-minimize"></i>
    </a>
    <!-- 모바일: 최소화 버튼 -->

    <template v-if="model.grup && model.slctIndx > -1">
      <!-- PC화면 좌상단 타이틀 영역 -->
      <div
        class="media-title btn-abs-pos-rb text-shadow-sm d-flex justify-content-between"
        :class="model.btnShow === true ? 'pad-btn-area' : ''">
        <!-- 컨텐츠 제목 -->
        <div v-show="model.grup.titl && model.grup.titl.length > 0" class="innr-titl d-flex">
          <i class="fa-regular fa-microchip-ai me-2 svg-shadow-sm"></i><div>{{ model.grup.titl }}</div>
        </div>
        <!-- 컨텐츠 제목 -->
        <!-- 이미지 해상도 표시 -->
        <div class="pc-imgs-rsol">
          <i class="fa-regular fa-images me-2 svg-shadow-sm" />
          <span class="text-primary fw-bold">{{ model.slctIndx + 1 }}</span>
          <span class="mx-1">of</span>
          <span class="me-1">
          {{ model.imgList.length }}
          </span>
          <span v-if="model.imgList.length > 0 && model.slctIndx < model.imgList.length"
            >({{ model.imgList[model.slctIndx].orgnWdth }} x
            {{ model.imgList[model.slctIndx].orgnHegt }}px)</span
          >
        </div>
        <!-- 이미지 해상도 표시 -->
      </div>
      <!-- PC화면 좌상단 타이틀 영역 -->

      <!-- 모바일: 이미지 해상도 표시 -->
      <div class="mobl-imgs-rsol">
        <div class="lb-logo-gen8">
          <img src="/aset/icon-generated-red.svg" />
        </div>
        <div class="d-flex flex-grow-1 d-none d-lg-flex pt-1">
          <div class="ps-2"><i class="fa-regular fa-message-quote me-1"></i>Cite Sources</div>
          <div class="ms-2 d-none d-md-flex">
            <span class="badge badge-outline-white">S</span>
            <div>Image from Generated</div>
            <span @click="emit('copyClbdSmalCite')" role="button" class="ms-1 badge badge-outline-white float-end">
              <i class="fa-solid fa-scissors"></i>
            </span>
          </div>
          <div class="ms-2 d-none d-xl-flex">
            <span class="badge badge-outline-white">M</span>
            <div>Image by &quot;{{ model.grup.crtrNm }}&quot; from Generated</div>
            <span @click="emit('copyClbdMidmCite')" role="button" class="ms-1 badge badge-outline-white float-end">
              <i class="fa-solid fa-scissors"></i>
            </span>
          </div>
        </div>
        <div class="txt-rsol text-shadow-sm text-end">
          <i class="fa-regular fa-images me-2 svg-shadow-sm" />
          <span class="text-primary fw-bold">{{ model.slctIndx + 1 }}</span> of
          {{ model.imgList.length }}
          <span v-if="model.imgList.length > 0 && model.slctIndx < model.imgList.length"
            >({{ model.imgList[model.slctIndx].orgnWdth }} x
            {{ model.imgList[model.slctIndx].orgnHegt }} px)</span
          >
        </div>
      </div>
      <!-- 모바일: 이미지 해상도 표시 -->
    </template>
  </div>
  <!-- root: zoom-wrap -->
</template>

<script setup>
// splideJs. ref. https://splidejs.com/guides/themes/
import "@splidejs/splide/css/sea-green"
import "@/assets/css/zoom-spld.css"
import { onMounted, reactive, ref, watch } from "vue"

const prop = defineProps(["imgList", "slctIndx", "btnShow", "grup", "step", "rlodFin", 'noKey'])
const emit = defineEmits(["currItemId", "onRemvRsltImg", "onRsltImgAdd", "setFullScrn", "onImgLoad", "copyClbdSmalCite", "copyClbdMidmCite"])
const zoomComp = ref()
const splideComp = ref()
const WIDTH_SM = "100%"
const HEIGHT_SM = "70px"
const WIDTH_LG = "100%"
const HEIGHT_LG = "100%"
const DIR_VERT = "ttb"
const DIR_HORZ = "ltr"
let kybdYn = prop.noKey ? prop.noKey : 'global'
const horzOptnSm = {
  slideFocus: false,
  width: WIDTH_SM,
  height: HEIGHT_SM,
  perPage: 6,
  perMove: 1,
  focus: 0,
  keyboard: kybdYn,
  rewind: false,
  gap: "1px",
  updateOnMove: true,
  pagination: true,
  paginationDirection: DIR_HORZ,
  direction: DIR_HORZ,
  cover: true,
  isNavigation: true
}
// https://splidejs.com/guides/options/#keyboard
const horzOptnMd = {
  slideFocus: false,
  width: WIDTH_SM,
  height: HEIGHT_SM,
  perPage: 9,
  perMove: 1,
  focus: 0,
  keyboard: kybdYn,
  rewind: false,
  gap: "1px",
  updateOnMove: true,
  pagination: true,
  paginationDirection: DIR_HORZ,
  direction: DIR_HORZ,
  cover: true,
  isNavigation: true
}
const horzOptnLg = {
  slideFocus: false,
  width: WIDTH_SM,
  height: HEIGHT_SM,
  perPage: 12,
  perMove: 1,
  focus: 0,
  keyboard: kybdYn,
  rewind: false,
  gap: "1px",
  updateOnMove: true,
  pagination: true,
  paginationDirection: DIR_HORZ,
  direction: DIR_HORZ,
  cover: true,
  isNavigation: true
}
const vertOptn = {
  slideFocus: false,
  width: WIDTH_LG,
  height: HEIGHT_LG,
  perPage: 12,
  perMove: 1,
  focus: 0,
  keyboard: kybdYn,
  rewind: false,
  gap: "1px",
  updateOnMove: true,
  pagination: true,
  paginationDirection: DIR_VERT,
  direction: DIR_VERT,
  cover: true,
  isNavigation: true
}
const model = reactive({
  init: true,
  btnShow: false,
  zoomed: false,
  slctIndx: 0,
  imgList: [],
  grup: null,
  imgSrc: null,
  imgAlt: null,
  step: null,
  loaded: new Set(),
  maxWidth: "",
  options: horzOptnMd
})
let splideController = null

watch(
  () => prop.imgList,
  (param) => {
    // console.log('new img list', newParam)
    model.imgList = param
  }
)

watch(
  () => prop.grup,
  (param) => {
    // console.log('new img list', newParam)
    model.grup = param
  }
)

watch(
  () => prop.rlodFin,
  (newParam) => {
    console.log('prop.rlodFin', newParam, prop.rlodFin)
    if (newParam && newParam > -1) {
      model.slctIndx = newParam
      displayBigImg(newParam)
    }
  }
)

watch(
  () => prop.slctIndx,
  (newParam) => {
    console.log("[NEW-IDX]", newParam)
    model.slctIndx = newParam
    displayBigImg()
  }
)

onMounted(() => {
  console.log('path', location.path)
  // horzOptnSm, horzOptnMd, horzOptnLg, vertOptn
  if (location.href.indexOf('/view/rgst') > -1) {

  }
  window.onresize = onResize
  if (prop.btnShow && (prop.btnShow === "true" || prop.btnShow === true)) {
    model.btnShow = true
  }
  if (prop.step && prop.step==='rgst') {
    model.step = prop.step
  }
  model.grup = prop.grup
  model.imgList = prop.imgList

  const params = new URL(location.href).searchParams
  const itemId = (params && params.get("d")) ? params.get("d") : null
  if (itemId!=null) {
    let idx = 0
    model.imgList.forEach((item) => {
      //console.log(`param ${itemId} vs ${item.id}`)
      if (item.id === itemId) {
        model.slctIndx = idx
      }
      idx++
    })
  } else {
    if (prop.slctIndx && prop.slctIndx > -1) {
      model.slctIndx = prop.slctIndx
    }
  }
  onResize()
  displayBigImg()
})

// https://splidejs.com/tutorials/thumbnail-carousel/
function onResize(splide) {
  const pageWidth = document.body.offsetWidth
  // console.log(`pageWidth ${pageWidth}`)
  // const wrapWidth = document.getElementById("img-ctnt-wrap")
  // if (wrapWidth) {
  if (pageWidth < 420) {
    model.options = horzOptnSm
  } else if (pageWidth < 768) {
    model.options = horzOptnMd
  } else if (pageWidth < 1440) {
    model.options = horzOptnLg
  } else {
    model.options = vertOptn
    document.getElementById("splide-wrap").classList.add("w-120px")
  }
}

const onSplideResize = (splide) => {
  // console.log(`onSplideResize`, JSON.stringify(splide))
  onResize(splide)
  splideController = splide.Components.Controller
  if (model.slctIndx < 0) {
    model.slctIndx = 0
  }
  splideController.setIndex(model.slctIndx)
}

const onSplideMove = (splide, index) => {
  model.slctIndx = index
  displayBigImg()
}

function onImgLoad() {
  emit('onImgLoad', false)
}

function displayBigImg(newParam) {
  if (model.imgList.length < 1) {
    return
  }
  const idx = model.slctIndx
  emit("currItemId", model.imgList[idx].id, idx)
  if (!model.loaded.has(idx)) {
    model.imgSrc = null
    model.loaded.add(idx)
    emit('onImgLoad', true)
  }
  if (newParam && newParam > -1) {
    model.imgSrc = model.imgList[newParam].pblcWtmkUrl ?? model.imgList[newParam].prvtOrgnUrl
    model.imgAlt = model.imgList[newParam].titl ?? ""
  } else {
    model.imgSrc = model.imgList[idx].pblcWtmkUrl ?? model.imgList[idx].prvtOrgnUrl
    model.imgAlt = model.imgList[idx].titl ?? ""
  }
  if (model.init === false && !model.step) {
    if (idx>0) {
      // console.log('d', JSON.stringify(model.imgList[idx]))
      history.replaceState({}, null, location.pathname + "?d=" + model.imgList[idx].id)
    } else {
      history.replaceState({}, null, location.pathname)
    }
  }
  model.init = false
  calcMaxWidth()
}

function calcMaxWidth() {
  if (model.slctIndx < 0 || model.imgList[model.slctIndx]==null) {
    return
  }
  let ret = "100%"
  const zoomerWidth = zoomComp.value.offsetWidth
  if (model.imgList[model.slctIndx].orgnWdth < zoomerWidth) {
    ret = model.imgList[model.slctIndx].orgnWdth + "px"
  }
  model.maxWidth = ret
}

function getWtmkUrl() {
  return model.imgSrc
}

defineExpose({ getWtmkUrl })
</script>

<style scoped>
.media-title {
  width: 100%;
  height: fit-content;
  top: 0;
  left: 0;
  line-height: 26px;
  font-size: 1.1rem;
  padding: 12px 0 0 1rem;
}

.btn-lg > svg {
  height: 26px;
}

.mobl-btn-max {
  left: 8px;
  bottom: 11px;
  display: none;
}

.btn-outline-white {
  color: white;
  border-color: white;
  background-color: rgba(0, 0, 0, 0.4);
}

.btn-func-area {
  z-index: 1;
}

.txt-rsol {
  line-height: 26px;
  font-weight: lighter;
}

.innr-titl, .innr-titl > div {
  height: 16px;
  line-height: 1;
}

.mobl-imgs-rsol .badge {
  cursor: unset;
}

@media (max-width: 991px) {
  .media-title {
    display: none !important;
  }

  .innr-titl {
    display: none;
  }

  .modal .fullscreen .mobl-btn-max {
    display: block;
  }
}

@media (min-width: 992px) {
  .mobl-btn-max {
    display: none;
  }
}

@media (max-width: 1439px) {
  .media-title {
    width: 100%;
  }

  .mobl-imgs-rsol {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    bottom: 82px;
    right: 0;
    color: white;
    padding: 0 0.9rem;
    font-size: 0.9rem;
  }

  .mobl-imgs-rsol img {
    height: 26px;
  }

  .pc-imgs-rsol {
    display: none;
  }
}

@media (min-width: 1440px) {
  .mobl-imgs-rsol {
    display: none;
  }

  .pc-imgs-rsol {
    display: flex;
    font-weight: normal;
    height: 16px;
    line-height: 16px;
  }
}
</style>
