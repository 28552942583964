<template>
	<!--begin::Modal - 내정보-->
	<div class="modal" tabindex="-1">
		<!--begin::Modal dialog-->
		<div class="modal-dialog modal-sm">
			<!--begin::Modal content-->
			<div class="modal-content">
				<!--begin::Modal body-->
				<div class="modal-body" v-if="model.citeInfo">

					<div class="modal-header">
						<h3 class="mx-2 my-1"><i class="fa-regular fa-message-quote me-2"></i> Quot Image Source</h3>
					</div>

          <div class="modal-body">

            <div class="mt-1 img-wrap text-center" >
              <img :src="model.citeInfo.imgUrl" />
            </div>

            <div class="text-center pt-1 pb-3">
              <div v-if="model.quotLen==='s'" class="ellipsis fs-7">Image from Generated</div>
              <div v-if="model.quotLen==='m'" class="ellipsis fs-7">Image by &quot;{{ model.citeInfo.crtrNm }}&quot; from Generated</div>
              <div v-if="model.quotLen==='l'" class="ellipsis fs-7">
                &lt; {{ model.citeInfo.crtrNm }},&nbsp;"{{ model.citeInfo.titl }}", online image, {{ model.citeInfo.aprvDttm?isoDateToOnlyYear4(model.citeInfo.aprvDttm):'' }}, Generated &gt;
              </div>
            </div>

            <div class="px-3 mb-3">
              <textarea class="w-100 text-primary p-2">{{ model.htmlTag?model.htmlTag:'Now in Construction' }}</textarea>
            </div>

            <div class="btn-group text-center w-100 px-5">
              <span @click="toSmamQuot()" class="btn btn-outline-primary">
                Short
              </span>
              <span @click="toMedmQuot()" class="btn btn-outline-primary">
                Medium
              </span>
              <span @click="toFullQuot()" class="btn btn-outline-primary">
                Full
              </span>
            </div>

            <div class="mx-3">
              <p class="text-dark fs-8 fw-light indt-titl mt-3">
                ※ The style of text and image can be adjusted, except the original website name and the URL link.
              </p>
            </div>

          </div>

				</div>
				<!--end::Modal body-->

				<btn-close-top-right @onCloseClick="emit('closeModal')" />

			</div>
			<!--end::Modal content-->
		</div>
		<!--end::Modal dialog-->
	</div>
	<!--end::Modal - 내정보-->

</template>

<script setup>
import { onMounted, reactive } from 'vue'
import { user_stor } from '@/stor/user_stor'
import { cnst_stor } from '@/stor/cnst_stor'
import BtnCloseTopRight from '@/view/btns/BtnCloseTopRight.vue'
import { mdal_stor } from '@/stor/mdal_stor'

const mdal = mdal_stor()
const emit  = defineEmits(['closeModal'])
const cnst = cnst_stor()
const user = user_stor()
const prop = defineProps(['citeInfo'])

const model = reactive({
  citeInfo: null,
  quotLen: 'l'
})

onMounted(() => {
  model.citeInfo = prop.citeInfo
})

function toSmamQuot() {

  model.quotLen = 's'

  const imgUrl = model.imgList[model.slctIdx].pblcThumUrl.replace('t.avif', 'm.webp')
  model.imgTag = '<img src="' + WEBSITE_HOST + imgUrl + '" alt="' + model.grup.crtrNm + ': ' + model.grup.titl + '. | Generated" />'
  model.citeTag = 'Image from '
    + '<a href="' + location.href + '" target="_blank" style="color: inherit;font-weight:normal !important;letter-spacing:-0.5px;border:none;display:inline-block;">'
    + 'Generated</a>'
  window.navigator.clipboard
    .writeText(model.imgTag + model.citeTag)
    .then(() => {
      mdal.toast(
        '<i class="fa-regular fa-copy me-2"></i>The Content HTML to<br/>embed has been copied<br/>to the Clipboard'
      )
    })
}
// /ress/pmpt/imgs/241025/16RNe29tp1H/scewdvzsw7zafnfsl8kst.avif
// /ress/pmpt/imgs/241025/16RNe29tp1H/scewdvzsw7zafnfsl8ksm.webp
function toMedmQuot() {

  model.quotLen = 'm'

  const imgUrl = model.imgList[model.slctIdx].pblcThumUrl.replace('t.avif', 'm.webp')
  model.imgTag = '<img src="' + WEBSITE_HOST + imgUrl + '" alt="' + model.grup.crtrNm + ': ' + model.grup.titl + '. | Generated" />'
  model.citeTag = 'Image by &quot;' + model.grup.crtrNm + '&quot; from ' // or on
    + '<a href="' + location.href + '" target="_blank" style="color: inherit;text-decoration:none;font-weight:normal !important;letter-spacing:-0.5px;border:none;display:inline-block;">'
    + 'Generated'
    + '</a>'
  window.navigator.clipboard
    .writeText(model.imgTag + model.citeTag)
    .then(() => {
      mdal.toast(
        '<i class="fa-regular fa-copy me-2"></i>The Content HTML to<br/>embed has been copied<br/>to the Clipboard'
      )
    })
}

function toFullQuot() {

  model.quotLen = 'l'

  const imgUrl = model.imgList[model.slctIdx].pblcThumUrl.replace('t.avif', 'm.webp')
  model.imgTag = '<img src="' + WEBSITE_HOST + imgUrl + '" alt="' + model.grup.crtrNm + ': ' + model.grup.titl + '. | Generated" />'
  model.citeTag = `<small class="font-size: 11px; !important">&lt;${model.grup.crtrNm}, &quot;${model.grup.titl}&quot;, ` // , Online Image, ${model.grup.aprvDttm?isoDateToOnlyYear4(model.grup.aprvDttm):''}
    + '<a href="' + location.href + '" target="_blank" style="color: inherit;text-decoration:none;font-weight:normal !important;letter-spacing:-0.5px;border:none;display:inline-block;">'
    + 'Generated'
    + '</a>&gt;</small>'
  window.navigator.clipboard
    .writeText(model.imgTag + model.citeTag)
    .then(() => {
      mdal.toast(
        '<i class="fa-regular fa-copy me-2"></i>The Content HTML to<br/>embed has been copied<br/>to the Clipboard'
      )
    })
}

</script>

<style scoped>
.modal-dialog {
  height: fit-content;
  overflow-x: hidden;
  width: 600px;
  max-width: calc(100% - 2rem);
}

.img-wrap {
  overflow: hidden;
  width: 588px;
  height: 588px;
}

.img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: calc(100% - 2rem);
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 600px;
  }
}
</style>
